import { ReactNode, useCallback, useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { ScreenRoutePath } from 'src/App/router/hooks'
import Notice, { NoticeProps } from 'src/_shared/components/Notice'
import ScreenContainer from 'src/_shared/components/ScreenContainer'
import TopBarButton from 'src/_shared/components/ScreenContainer/components/TopBarButton'
import ArrowLeftIcon from 'src/_shared/components/_icons/ArrowLeftIcon'
import QuestionMarkCircledIcon from 'src/_shared/components/_icons/QuestionMarkCircledIcon'
import { APP_MODE, BACK_LINK, BRAND } from 'src/_shared/constants/env'
import { AppMode, Brand } from 'src/_shared/enums/env'
import { useAuthContext } from 'src/_shared/hooks/useAuthContext'
import { useStrapiContext } from 'src/_shared/hooks/useStrapiContext'
import { ChargerScreenTransientFlowFeatureToggles } from 'src/_shared/queries/strapi'
import { classNames } from 'src/_shared/utils/elements'

import ChargingView from './components/ChargingView'
import EndRedirectView from './components/EndRedirectView'
import PaymentMethodsView from './components/PaymentMethodsView'
import PostChargingView from './components/PostChargingView'
import PreChargingView from './components/PreChargingView'
import ReceiptView from './components/ReceiptView'
import TransientEmailPromptView from './components/TransientEmailPromptView'
import TransientEmailView from './components/TransientEmailView'
import TransientEnterOtpView from './components/TransientEnterOtpView'
import TransientPaymentMethodsView from './components/TransientPaymentMethodsView'
import { ChargerScreenViewItemKey } from './enums'
import {
	ChargerScreenRouteParams,
	ChargerScreenUpdateViewFunction,
	ChargerScreenViewItem
} from './types'

const ChargerScreen = (): JSX.Element => {
	const [viewItemKey, setViewItemKey] = useState<ChargerScreenViewItemKey>(
		ChargerScreenViewItemKey.PreChargingView
	)

	const [errorViewProps, setErrorViewProps] = useState<NoticeProps>({})

	/**
	 * If the screen can be viewed, then `routeParams` are defined.
	 * Otherwise, the router would redirect the user back to the Map screen.
	 */
	const routeParams = useParams() as unknown as ChargerScreenRouteParams

	const { isAuthenticated } = useAuthContext()

	const { getFeatureToggles } = useStrapiContext()

	const isTransientFlow = APP_MODE === AppMode.Transient

	const { isEmailOptional } = useMemo((): ChargerScreenTransientFlowFeatureToggles => {
		return getFeatureToggles('chargerScreen')?.transientFlow ?? {}
	}, [getFeatureToggles])

	const updateView: ChargerScreenUpdateViewFunction = useCallback((...args): void => {
		const nextKey = args[0]
		if (nextKey === ChargerScreenViewItemKey.ErrorView) {
			const nextErrorViewProps = args[1]
			if (nextErrorViewProps) {
				setErrorViewProps(nextErrorViewProps)
			}
		}
		setViewItemKey((previousKey): ChargerScreenViewItemKey => {
			console.debug(`[ChargerScreen] Update View: "${previousKey}" > "${nextKey}"`)
			return nextKey
		})
	}, [])

	const viewItemsMap = useMemo((): Record<ChargerScreenViewItemKey, ChargerScreenViewItem> => {
		const handleBackLinkRedirect = (): void => {
			if (BACK_LINK) {
				window.location.href = BACK_LINK
			}
		}

		const handleActiveSessionBack = BACK_LINK
			? handleBackLinkRedirect
			: // Fallback to default `handleReturn` inside of `TopBar`
				null

		const handleReceiptClose = BACK_LINK
			? handleBackLinkRedirect
			: // Go back to the start of the Charging Flow
				(): void => {
					updateView(ChargerScreenViewItemKey.PreChargingView)
				}

		return {
			[ChargerScreenViewItemKey.PreChargingView]: {
				view: (
					<PreChargingView
						isAuthenticated={isAuthenticated}
						routeParams={routeParams}
						updateView={updateView}
					/>
				)
			},
			// Transient Flow
			[ChargerScreenViewItemKey.TransientEmailPromptView]: {
				view: <TransientEmailPromptView routeParams={routeParams} updateView={updateView} />,
				onBack: (): void => {
					updateView(ChargerScreenViewItemKey.PreChargingView)
				}
			},
			[ChargerScreenViewItemKey.TransientEmailView]: {
				view: <TransientEmailView routeParams={routeParams} updateView={updateView} />,
				onBack: (): void => {
					if (isEmailOptional) {
						updateView(ChargerScreenViewItemKey.TransientEmailPromptView)
					} else {
						updateView(ChargerScreenViewItemKey.PreChargingView)
					}
				}
			},
			[ChargerScreenViewItemKey.TransientEnterOtpView]: {
				view: (
					<TransientEnterOtpView
						routeParams={routeParams}
						onNext={(): void => {
							updateView(ChargerScreenViewItemKey.TransientPaymentMethodsView)
						}}
					/>
				),
				onBack: (): void => {
					updateView(ChargerScreenViewItemKey.TransientEmailView)
				}
			},
			[ChargerScreenViewItemKey.TransientPaymentMethodsView]: {
				view: (
					<TransientPaymentMethodsView
						routeParams={routeParams}
						onNext={(): void => {
							updateView(ChargerScreenViewItemKey.ChargingView)
						}}
						updateView={updateView}
					/>
				),
				onBack: (): void => {
					if (isEmailOptional) {
						const urlSearchParams = new URLSearchParams(window.location.search)
						const isEmailProvided = urlSearchParams.has('email')
						if (!isEmailProvided) {
							updateView(ChargerScreenViewItemKey.TransientEmailPromptView)
							return
						}
					}
					updateView(ChargerScreenViewItemKey.TransientEmailView)
				}
			},
			// Default Flow
			[ChargerScreenViewItemKey.PaymentMethodsView]: {
				view: (
					<PaymentMethodsView
						routeParams={routeParams}
						onNext={(): void => {
							updateView(ChargerScreenViewItemKey.ChargingView)
						}}
						updateView={updateView}
					/>
				),
				onBack: (): void => {
					updateView(ChargerScreenViewItemKey.PreChargingView)
				}
			},
			[ChargerScreenViewItemKey.ChargingView]: {
				hideBack: isTransientFlow,
				view: (
					<ChargingView
						isAuthenticated={isAuthenticated}
						routeParams={routeParams}
						onNext={(): void => {
							updateView(ChargerScreenViewItemKey.PostChargingView)
						}}
						updateView={updateView}
					/>
				),
				onBack: handleActiveSessionBack
			},
			[ChargerScreenViewItemKey.PostChargingView]: {
				hideBack: isTransientFlow,
				view: (
					<PostChargingView
						routeParams={routeParams}
						onNext={(): void => {
							// Handling for partners that do not need the receipt and/or want
							// to redirect back to their application or website.
							switch (BRAND) {
								case Brand.Evme: {
									updateView(ChargerScreenViewItemKey.EndRedirectView)
									break
								}
								default: {
									updateView(ChargerScreenViewItemKey.ReceiptView)
								}
							}
						}}
					/>
				),
				onBack: handleActiveSessionBack
			},
			[ChargerScreenViewItemKey.ReceiptView]: {
				view: <ReceiptView routeParams={routeParams} onNext={handleReceiptClose} />,
				onBack: handleReceiptClose
			},
			[ChargerScreenViewItemKey.EndRedirectView]: {
				view: (
					<EndRedirectView
						routeParams={routeParams}
						onNext={(): void => {
							// Fallback if redirect fails
							updateView(ChargerScreenViewItemKey.ReceiptView)
						}}
					/>
				)
			},
			[ChargerScreenViewItemKey.ErrorView]: {
				view: <Notice {...errorViewProps} />,
				onBack: (): void => {
					updateView(ChargerScreenViewItemKey.PreChargingView)
					setErrorViewProps({})
				}
			}
		}
	}, [errorViewProps, isAuthenticated, isEmailOptional, isTransientFlow, routeParams, updateView])

	const currentViewItem = viewItemsMap[viewItemKey]

	return (
		<ScreenContainer
			className={classNames(
				viewItemKey === ChargerScreenViewItemKey.ReceiptView
					? 'print:min-h-0 print:max-w-none'
					: null
			)}
			contentViewProps={{
				className: 'px-5 pt-6'
			}}
			topBarProps={{
				leftRender: ((): ReactNode => {
					if (currentViewItem.hideBack) {
						return false
					}
					return (
						currentViewItem.onBack && (
							<TopBarButton data-testid="cs-btn-back" onClick={currentViewItem.onBack}>
								<ArrowLeftIcon className="h-4" />
							</TopBarButton>
						)
					)
				})(),
				rightRender: isAuthenticated ? (
					<Link to={ScreenRoutePath.AccountHelp}>
						<TopBarButton data-testid="cs-btn-help">
							<QuestionMarkCircledIcon className="h-4" />
						</TopBarButton>
					</Link>
				) : null
			}}
			hideBottomBar
		>
			{currentViewItem.view}
		</ScreenContainer>
	)
}

export default ChargerScreen
