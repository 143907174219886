import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'
import { CSSProperties } from 'react'

import { BRAND, STRAPI_URL } from '../constants/env'
import { Language } from '../enums/env'

export const ROOT_STRAPI_QUERY_KEY = 'Strapi'

export enum StrapiQueryKey {
	StrapiBrand = 'StrapiBrand',
	StrapiTranslations = 'StrapiTranslations',
	StrapiFaq = 'StrapiFaq',
	StrapiTermsAndConditions = 'StrapiTermsAndConditions'
}

type StrapiError = Partial<{
	status: number
	name: string
	message: string
	details: Partial<Record<string, unknown>>
}>

export interface StrapiResponse<T> {
	data: T | null
	error?: StrapiError
	meta?: Partial<Record<string, unknown>>
}

interface StrapiPaginatedResponse<T> {
	data: T[]
	error?: StrapiError
	meta?: Partial<{
		pagination: Partial<{
			page: number
			pageCount: number
			pageSize: number
			total: number
		}>
	}>
}

interface StrapiImageData {
	id: number
	attributes: Partial<{
		name: string
		alternativeText: string
		caption: string
		width: number
		height: number
		formats: Partial<{
			thumbnail: Partial<{
				name: string
				hash: string
				ext: string
				mime: string
				path: string
				width: number
				height: number
				size: number
				url: string
			}>
			small: Partial<{
				name: string
				hash: string
				ext: string
				mime: string
				path: string
				width: number
				height: number
				size: number
				url: string
			}>
		}>
		hash: string
		ext: string
		mime: string
		size: number
		url: string
		previewUrl: null
		provider: string
		provider_metadata: string
		createdAt: string
		updatedAt: string
	}>
}

export type ChargerScreenPreChargingViewFeatureToggles = Partial<{
	showIntermediateModalConnectReminder: boolean
	skipIntermediateModal: boolean
}>

export type ChargerScreenTransientFlowFeatureToggles = Partial<{
	isEmailOptional: boolean
	requireEmailOtpVerification: boolean
}>

export type ChargerScreenUseChargerItemListsFeatureToggles = Partial<{
	hideTaxIndicators: boolean
}>

export type CheckInFlowFeatureToggles = Partial<{
	isQrCodeOnly: boolean
}>

export type ReceiptDetailsFeatureToggles = Partial<{
	showDownloadButton: boolean
}>

export type StrapiFeatureToggles = Partial<{
	chargerScreen: Partial<{
		preChargingView: ChargerScreenPreChargingViewFeatureToggles
		transientFlow: ChargerScreenTransientFlowFeatureToggles
		useChargerItemLists: ChargerScreenUseChargerItemListsFeatureToggles
	}>
	checkInFlow: CheckInFlowFeatureToggles
	receiptDetails: ReceiptDetailsFeatureToggles
}>

export interface StrapiBrandData {
	id: number
	attributes: Partial<
		// Default Attributes
		{
			name: string
			/**
			 * Format: `YYYY-MM-DDTHH:mm:ss.SSSZ`.
			 */
			createdAt: string
			/**
			 * Format: `YYYY-MM-DDTHH:mm:ss.SSSZ`.
			 */
			publishedAt: string
			/**
			 * Format: `YYYY-MM-DDTHH:mm:ss.SSSZ`.
			 */
			updatedAt: string
		} & Partial<{
			// Custom Key-Values
			logoDark: Partial<{
				data: StrapiImageData
			}>
			logoLight: Partial<{
				data: StrapiImageData
			}>
			configuration: Partial<{
				cpoSortOrder: string[]
				checkInCpoOptions: Partial<{
					name: string
					entityCode: string
					imageUrl: string
					disableScanQrButton: boolean
				}>[]
				featureToggles: StrapiFeatureToggles
				receipt: Partial<{
					companyNameTo: string
					companyNameOf: string
					footer: string[]
				}>
				cpoOptions: Partial<{
					name: string
					entityCode: string
				}>[]
				languages: Partial<{
					label: string
					value: Language
					description: string
				}>[]
			}>
			theme: Partial<{
				rootColors: Partial<{
					primary: string
				}>
				components: Partial<{
					priceModifierTag: CSSProperties
					topBar: CSSProperties &
						Partial<{
							logo: CSSProperties &
								Partial<{
									mode: 'dark' | 'light'
								}>
						}>
				}>
				/**
				 * Only for use in `tailwind.config.js`.
				 */
				tailwindConfiguration: Partial<Record<string, unknown>>
			}>
		}>
	>
}

export const useStrapiBrandQuery = <TData = StrapiBrandData>(
	options?: Omit<
		UseQueryOptions<StrapiBrandData, AxiosError, TData, [string, StrapiQueryKey.StrapiBrand]>,
		'queryFn' | 'queryKey'
	>
) => {
	return useQuery({
		...options,
		queryKey: [ROOT_STRAPI_QUERY_KEY, StrapiQueryKey.StrapiBrand],
		queryFn: async (): Promise<StrapiBrandData> => {
			try {
				const response = await axios.get<StrapiPaginatedResponse<StrapiBrandData>>(
					`${STRAPI_URL}/api/brands`,
					{
						params: {
							'filters[name][$eq]': BRAND,
							populate: '*'
						}
					}
				)
				// Assumes the first entry is the correct data.
				// Note: Should not need to be concerned about pagination meta as long as the brand name is unique.
				if (response.data.data.length === 0) {
					console.debug('[useStrapiBrandQuery] No Brand Data Found')
					return {
						id: -1,
						attributes: {}
					}
				}
				return response.data.data[0]
			} catch (error) {
				const axiosError = error as AxiosError<StrapiPaginatedResponse<StrapiBrandData>>
				return Promise.reject(axiosError)
			}
		}
	})
}

interface StrapiTranslationsQueryParams {
	locale: Language
}

interface StrapiTranslationsData {
	id: number
	attributes: Partial<{
		translations: Record<string, string>
		/**
		 * Format: `YYYY-MM-DDTHH:mm:ss.SSSZ`.
		 */
		createdAt: string
		/**
		 * Format: `YYYY-MM-DDTHH:mm:ss.SSSZ`.
		 */
		updatedAt: string
		/**
		 * Format: `YYYY-MM-DDTHH:mm:ss.SSSZ`.
		 */
		publishedAt: string
		locale: Language
	}>
}

export const useStrapiTranslationsQuery = <TData = StrapiTranslationsData>(
	params: StrapiTranslationsQueryParams,
	options?: Omit<
		UseQueryOptions<
			StrapiTranslationsData,
			AxiosError,
			TData,
			[string, StrapiQueryKey.StrapiTranslations, StrapiTranslationsQueryParams]
		>,
		'queryFn' | 'queryKey'
	>
) => {
	return useQuery({
		...options,
		queryKey: [ROOT_STRAPI_QUERY_KEY, StrapiQueryKey.StrapiTranslations, params],
		queryFn: async (): Promise<StrapiTranslationsData> => {
			try {
				const { locale } = params
				const response = await axios.get<StrapiResponse<StrapiTranslationsData>>(
					`${STRAPI_URL}/api/translation`,
					{
						params: {
							locale
						}
					}
				)
				const data = response.data.data
				if (!data) {
					console.debug('[useStrapiTranslationsQuery] No Translations Data Found')
					return {
						id: -1,
						attributes: {}
					}
				}
				return data
			} catch (error) {
				const axiosError = error as AxiosError<StrapiResponse<StrapiTranslationsData>>
				return Promise.reject(axiosError)
			}
		}
	})
}
