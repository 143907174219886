import { useParams } from 'react-router-dom'
import Error404Notice from 'src/_shared/components/Error404Notice'
import ReceiptDetails from 'src/_shared/components/ReceiptDetails'
import ScreenContainer from 'src/_shared/components/ScreenContainer'
import Spinner from 'src/_shared/components/Spinner'
import { APP_MODE } from 'src/_shared/constants/env'
import { AppMode } from 'src/_shared/enums/env'
import { useSessionQuery, useSessionTransientQuery } from 'src/_shared/queries/sessions'
import { OmniSession } from 'src/_shared/types/omni'

interface HistoryDetailScreenRouteParams {
	sessionId: string
}

const HistoryDetailScreen = (): JSX.Element => {
	const routeParams = useParams() as unknown as HistoryDetailScreenRouteParams

	const sessionId = routeParams.sessionId

	const isTransient = APP_MODE === AppMode.Transient

	const sessionQuery = useSessionQuery(
		{ sessionId },
		{
			enabled: !isTransient
		}
	)

	const sessionTransientQuery = useSessionTransientQuery({
		enabled: isTransient
	})

	const { data: session = null, status: sessionQueryStatus } = isTransient
		? sessionTransientQuery
		: sessionQuery

	return (
		<ScreenContainer
			className="print:min-h-0 print:max-w-none"
			contentViewProps={{ className: 'px-5 py-6' }}
			hideBottomBar
		>
			{sessionQueryStatus === 'pending' && (
				<div className="flex flex-grow flex-col items-center justify-center">
					<Spinner />
				</div>
			)}
			{(sessionQueryStatus === 'error' ||
				(sessionQueryStatus === 'success' && session === null)) && <Error404Notice />}
			{sessionQueryStatus === 'success' && session !== null && (
				<ReceiptDetails chargingSession={session as OmniSession} />
			)}
		</ScreenContainer>
	)
}

export default HistoryDetailScreen
